import React from "react";
import {
  FaLinkedin,
  FaGithub,
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaAndroid,
} from "react-icons/fa";
import styled from "styled-components";

const SocialIcon = styled.a`
  align-items: center;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  font-size: 1.2rem;
  height: 40px;
  width: 40px;
  vertical-align: middle;
  &:hover {
    background-color: rgba(55, 70, 80, 0.1);
  }
`;

const NavContainer = styled.nav`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: ${(props) => props.theme.color_theme_dark};
`;

const SocialIcons = () => {
  return (
    <NavContainer>
      <SocialIcon
        target="_blank"
        href="http://in.linkedin.com/in/pratikkataria/"
      >
        <FaLinkedin />
      </SocialIcon>
      <SocialIcon
        target="_blank"
        rel="noreferrer noopener"
        href="https://github.com/kpratik2015"
      >
        <FaGithub />
      </SocialIcon>
      <SocialIcon
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.facebook.com/pratik.kataria"
      >
        <FaFacebook />
      </SocialIcon>
      <SocialIcon
        target="_blank"
        rel="noreferrer noopener"
        href="https://twitter.com/PratikPKataria"
      >
        <FaTwitter />
      </SocialIcon>
      <SocialIcon
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.instagram.com/frenziecooper/"
      >
        <FaInstagram />
      </SocialIcon>
      <SocialIcon
        target="_blank"
        rel="noreferrer noopener"
        href="http://www.youtube.com/c/PratikKataria"
      >
        <FaYoutube />
      </SocialIcon>
      <SocialIcon
        href="https://play.google.com/store/apps/dev?id=8312708476046119513"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FaAndroid />
      </SocialIcon>
    </NavContainer>
  );
};

export default SocialIcons;
