import React from "react";
import SocialIcons from "./social-icons";
import styled from "styled-components";
import ScrollUpButton from "react-scroll-up-button";

const FooterContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${(props) => props.theme.flex_direction_mobile};
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing_m};
  background-color: ${(props) => props.theme.color_theme_mid};
  @media only screen and (min-width: ${(props) => props.theme.mobile_bp}) {
    flex-direction: ${(props) => props.theme.flex_direction_desktop};
  }
`;

const BackToTopButton = styled.button`
  border: 3px solid ${(props) => props.theme.color_theme_dark};
  transition: background-color 0.3s, color 0.3s;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
  font-size: 1rem;
  color: ${(props) => props.theme.color_theme_dark};
  &:hover {
    background-color: ${(props) => props.theme.color_theme_dark};
    color: ${(props) => props.theme.color_theme_mid};
  }
  &:active {
    background-color: transparent;
    color: ${(props) => props.theme.color_theme_dark};
  }
`;

const Footer = () => (
  <FooterContainer id="footer">
    <div>
      © {new Date().getFullYear()},{` `}Pratik Kataria
    </div>
    <ScrollUpButton ContainerClassName="ScrollUpButton_Container">
      <BackToTopButton>Back To Top</BackToTopButton>
    </ScrollUpButton>
    <SocialIcons />
  </FooterContainer>
);

export default Footer;
