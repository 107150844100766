export const reachedElement = (el) => {
  let rect = el.getBoundingClientRect();
  let elemTop = rect.top;
  let elemBottom = rect.bottom;

  // Only completely visible elements return true:
  // let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight
  // Partially visible elements return true:
  let isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
};

export const offsetElement = (el) => {
  let rect = el.getBoundingClientRect();
  let elemTop = rect.top;
  let elemBottom = rect.bottom;
  // Only completely visible elements return true:
  let isVisible = elemTop <= 0 && elemBottom >= 0;
  // Partially visible elements return true:
  //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return { offset: -1 * elemTop, visible: isVisible };
};

export const getElementVisibility = (element) => {
  const rect = element.getBoundingClientRect();
  const elementArea = rect.width * rect.height;
  let visibleWidth = rect.left >= 0 ? rect.width : rect.width + rect.left;
  if (visibleWidth < 0) {
    visibleWidth = 0;
  }
  let visibleHeight = rect.top >= 0 ? rect.height : rect.height + rect.top;
  if (visibleHeight < 0) {
    visibleHeight = 0;
  }
  const visibleArea = visibleWidth * visibleHeight;
  // ((input - min) * 100) / (max - min)
  return {
    topToBotVisibilityPercent: Math.min(
      Math.max(0, ((window.innerHeight - rect.top) * 100) / rect.height),
      100
    ),
    botToTopVisibilityPercent: (visibleArea / elementArea) * 100,
  };
};

export const scale = (num, in_min, in_max, out_min, out_max) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export default reachedElement;
