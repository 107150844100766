const theme = {
  color_theme_primary: "#cf4646",
  color_theme_light: "#fff",
  color_theme_mid: "#f0f0f0",
  color_theme_dark: "#252c36",
  color_text_light: "hsla(0, 0%, 100%, 0.4)",
  color_text_lighter: "hsla(0, 0%, 100%, 0.6)",
  color_text_lightest: "hsla(0, 0%, 100%, 0.9)",
  spacing_xs: "4px",
  spacing_s: "8px",
  spacing_m: "16px",
  fit_text_size_s: "0.9rem",
  fit_text_line_height_s: "1rem",
  fit_text_size_m: "1.2rem",
  fit_text_line_height_m: "1.5rem",
  fit_text_size_l: "1.5rem",
  fit_text_line_height_l: "2rem",
  spacing_l: "32px",
  spacing_xl: "64px",
  width_desktop: "1190px",
  flex_direction_mobile: "column",
  flex_direction_desktop: "row",
  color_grey: "#ababab",
  font_stack_body: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol"`,
  mobile_bp: "768px",
  tablet_bp: "1153px",
  text_shadow_neon_glow:
    "0 -40px 100px, 0 0 2px, 0 0 1em #ff4444, 0 0 0.5em #ff4444, 0 0 0.1em #ff4444, 0 10px 3px #000",
  boxShadows: {
    shadow100:
      "rgba(32,47,71,0.1) 0px 0.125rem 0.125rem 0px, rgba(32,47,71,0.05) 0px 0px 0.25rem 0px, rgba(32,47,71,0.03) 0px 0px 0px 0.0625rem",
    shadow200:
      "rgba(32, 47, 71, 0.08) 0px 0.1875rem 0.25rem -0.0625rem, rgba(32, 47, 71, 0.06) 0px 0.125rem 0.4375rem 0px, rgba(32, 47, 71, 0.04) 0px -0.1875rem 0.1875rem -0.125rem, rgba(32, 47, 71, 0.03) 0px 0px 0px 0.0625rem",
    shadow300:
      "rgba(32, 47, 71, 0.06) 0px 0.8125rem 0.4375rem -0.4375rem, rgba(32, 47, 71, 0.08) 0.625rem 0.25rem 0.4375rem -0.5rem, rgba(32, 47, 71, 0.08) -0.625rem 0.25rem 0.4375rem -0.5rem, rgba(32, 47, 71, 0.06) 0px 0.1875rem 0.375rem 0px, rgba(32, 47, 71, 0.04) 0px -0.25rem 0.5rem -0.125rem, rgba(32, 47, 71, 0.03) 0px 0px 0px 0.0625rem",
    shadow400:
      "rgba(32, 47, 71, 0.08) 0.75rem 0.25rem 0.75rem -0.5rem, rgba(32, 47, 71, 0.08) -0.75rem 0.25rem 0.75rem -0.5rem, rgba(32, 47, 71, 0.08) 0px 0.75rem 0.75rem -0.375rem, rgba(32, 47, 71, 0.06) 0px 0.375rem 0.5rem 0px, rgba(32, 47, 71, 0.02) 0px -0.5rem 0.5rem -0.125rem, rgba(32, 47, 71, 0.03) 0px 0px 0px 0.0625rem",
    shadow500:
      "rgba(32, 47, 71, 0.08) 0.9375rem 0.25rem 0.9375rem -0.5rem, rgba(32, 47, 71, 0.08) -0.9375rem 0.25rem 0.9375rem -0.5rem, rgba(32, 47, 71, 0.08) 0px 1.25rem 1.25rem -0.375rem, rgba(32, 47, 71, 0.06) 0px 0.875rem 0.625rem -0.25rem, rgba(32, 47, 71, 0.02) 0px -0.25rem 0.5rem -0.125rem, rgba(32, 47, 71, 0.03) 0px 0px 0px 0.0625rem",
    shadow600:
      "rgba(32, 47, 71, 0.08) 1.25rem 0.25rem 1.25rem -0.5rem, rgba(32, 47, 71, 0.08) -1.25rem 0.25rem 1.25rem -0.5rem, rgba(32, 47, 71, 0.08) 0px 1.25rem 1.625rem -0.125rem, rgba(32, 47, 71, 0.06) 0px 1.875rem 1.625rem -0.5rem, rgba(32, 47, 71, 0.03) 0px 0px 0px 0.0625rem",
  },
};

export default theme;
